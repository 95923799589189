import { useEffect, useState } from 'react';
import { setToast } from '../context/appReducer';
import { useAppDispatch, useAppSelector } from '../context/hooks';
import { RootState } from '../context/store';
import { Severity } from '../models/models';
import { ClassificationDto, ClassificationsService } from '../services/catalog';
import { setClassTreeNeedsReload } from '../context/classesReducer';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

/**
 * hook for get searched classifications
 * @returns list of classification based on given searchValue and loading that indicates if communication with server is still ongoing
 */
export default function useSearchedClassifications(searchValue: string) {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const [allClassifications, setAllClassifications] = useState<ClassificationDto[]>([]);
	const [classifications, setClassifications] = useState<ClassificationDto[]>([]);
	const classTreeNeedsReload = useAppSelector((state: RootState) => state.classes.classTreeNeedsReload);

	/**
	 * Loads all classifications.
	 */
	const loadAllClassifications = () => {
		ClassificationsService.getRootClassifications()
			.then(async (response: ClassificationDto[]) => {
				setAllClassifications(response);
				setClassifications(response);
				console.log('All classifications loaded', response);
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(
					setToast({
						show: true,
						message: t('errorFetchingData'),
						severity: Severity.warning,
					}),
				);
			});
	};

	/**
	 * load all classifications when class needs to reload
	 */
	useEffect(() => {
		if (classTreeNeedsReload) {
			loadAllClassifications();
			dispatch(setClassTreeNeedsReload(false));
		}
	}, [classTreeNeedsReload]);

	/**
	 * if searchValue is longer than 3 letters
	 * then get all classifications that contains this searchValue
	 * else get all classifications
	 */
	useEffect(() => {
		if (searchValue.trim().length < 3 && allClassifications.length === 0) {
			loadAllClassifications();
			return;
		}
		if (searchValue.trim().length < 3) {
			setClassifications(allClassifications);
			return;
		}

		//setLoading(true);
		ClassificationsService.searchClassifications(searchValue.trim())
			.then(async (response: ClassificationDto[]) => {
				setClassifications(response);
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(
					setToast({
						show: true,
						message: t('errorFetchingData'),
						severity: Severity.warning,
					}),
				);
			})
			.finally(async () => {
				//	setLoading(false);
			});
	}, [searchValue]);

	return { classifications };
}
