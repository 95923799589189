import React from 'react';
// STYLING
import './Modal.scss';
// COMPONENTS
import { Container, Modal, Row } from 'react-bootstrap';
import { Checkbox } from '@lbc-toolkit/checkbox';
// REDUX
import { useAppDispatch, useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';
import {
	setColumnsOptionsVisible,
	setDatatypeVisibility,
	setNameVisibility,
	setStatusVisibility,
	setUnittypeVisibility,
	setUnitVisibility,
} from '../../context/tableFilterReducer';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

const ColumnOptionsModal = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const columnsOptionsVisible: boolean = useAppSelector(
		(state: RootState) => state.tableFilter.columnsOptionsVisible,
	);
	const nameVisibility: boolean = useAppSelector((state: RootState) => state.tableFilter.nameVisibility);
	const datatypeVisibility: boolean = useAppSelector((state: RootState) => state.tableFilter.datatypeVisibility);
	const unittypeVisibility: boolean = useAppSelector((state: RootState) => state.tableFilter.unittypeVisibility);
	const unitVisibility: boolean = useAppSelector((state: RootState) => state.tableFilter.unitVisibility);
	const statusVisibility: boolean = useAppSelector((state: RootState) => state.tableFilter.statusVisibility);

	/**
	 * Called when cancel button gets pressed to close the modal.
	 */
	const OnCloseModalBtn = () => {
		dispatch(setColumnsOptionsVisible(false));
	};

	return (
		<Modal centered show={columnsOptionsVisible} onHide={OnCloseModalBtn}>
			<Modal.Header closeButton>
				<Modal.Title>{t('columnOptions')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row>
						<Checkbox
							checked={nameVisibility}
							label={t('name')}
							onChange={() => {
								dispatch(setNameVisibility(!nameVisibility));
							}}
						/>
					</Row>
					<Row>
						<Checkbox
							checked={datatypeVisibility}
							label={t('dataType')}
							onChange={() => {
								dispatch(setDatatypeVisibility(!datatypeVisibility));
							}}
						/>
					</Row>
					<Row>
						<Checkbox
							checked={unittypeVisibility}
							label={t('unitType')}
							onChange={() => {
								dispatch(setUnittypeVisibility(!unittypeVisibility));
							}}
						/>
					</Row>
					<Row>
						<Checkbox
							checked={unitVisibility}
							label={t('unit')}
							onChange={() => {
								dispatch(setUnitVisibility(!unitVisibility));
							}}
						/>
					</Row>
					<Row>
						<Checkbox
							checked={statusVisibility}
							label={t('status')}
							onChange={() => {
								dispatch(setStatusVisibility(!statusVisibility));
							}}
						/>
					</Row>
				</Container>
			</Modal.Body>
		</Modal>
	);
};

export default ColumnOptionsModal;
