import createTheme from "@mui/material/styles/createTheme";

const catalogTheme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: '#FFD000',
            '&.Mui-completed': {
              color: '#FFD000',
            },
            '&.Mui-active': {
              color: '#FFD000',
            },
          },
        },
      },
    },
  });
  export default catalogTheme; 