import { useQuery } from "@tanstack/react-query";
import { PropertiesService } from "../services/catalog";

const useUnitsNet = () => {

    const { data: unitTypes, isPending } = useQuery({
        queryKey: ['unitsNet'],
        queryFn: () => PropertiesService.getUnitTypes(),
    });

    function unitTypeByName(name: string) {
        return unitTypes?.find(unitType => unitType.name === name);
    }

    function unitByName(unitTypeName: string, unitName: string) {
        const type = unitTypeByName(unitTypeName);
        return type?.units?.find(unit => unit.name === unitName);
    }

    return {
        isPending,
        unitTypes: unitTypes ?? [],
        unitTypeByName,
        unitByName
    }
};

export default useUnitsNet;