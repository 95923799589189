// REACT
import React from 'react';
// STYLING
import '../../Details.scss';
// COMPONENTS
import { Modal } from 'react-bootstrap';
// REDUX
import { useAppSelector } from '../../../../context/hooks';
import { RootState } from '../../../../context/store';
import DetailsNavbar from '../../DetailsNavbar';
import ClassDetailsGeneralModalContent from './ClassDetailsGeneralModalContent';
import ClassDetailsPropertiesModalContent from './Mappings/ClassDetailsPropertiesModalContent';
import ClassDetailsLocalizationModalContent from './ClassDetailsLocalizationModalContent';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

const ClassDetailsModalContent = () => {
	const { t } = useTranslate();
	const activeDetailsTab = useAppSelector((state: RootState) => state.app.activeDetailsTab);
	const currentClass = useAppSelector((state: RootState) => state.classes.currentClass);

	/**
	 * Renders the content, based on a given string.
	 * @param activeDetailsTab Given string to differ between content.
	 */
	function renderContent(activeDetailsTab: string) {
		if (currentClass === null) {
			return <div />;
		}
		switch (activeDetailsTab) {
			case 'general':
				return <ClassDetailsGeneralModalContent />;
			case 'properties':
				return <ClassDetailsPropertiesModalContent />;
			case 'localization':
				return <ClassDetailsLocalizationModalContent />;
		}
	}

	return (
		<Modal.Body className='details-modal-content-layout'>
			<DetailsNavbar />
			<div className='details-content'>{renderContent(activeDetailsTab)}</div>
		</Modal.Body>
	);
};

export default ClassDetailsModalContent;
