//React
import React from 'react';
//Components
import { Paper } from '@mui/material';
import PropertyHeader from '../forms/Properties/PropertyHeader';
import PropertyTable from '../forms/Properties/PropertyTable';
//Style
import './Container.scss';

const PropertiesContainer = () => {
	return (
		<>
			<PropertyHeader />
			<PropertyTable />
		</>
	);
};

export default PropertiesContainer;
