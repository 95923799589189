// REACT
import React from 'react';
// STYLING
import './Modal.scss';
// COMPONENTS
import { Modal } from 'react-bootstrap';
import CreateClassModalContent from './Classes/Create/CreateClassModalContent';
import CreatePropertyModalContent from './Properties/Create/CreatePropertyModalContent';
// REDUX
import { useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

const CreateModal = () => {
	const { t } = useTranslate();
	const activeTab = useAppSelector((state: RootState) => state.app.activeTab);
	const createMode = useAppSelector((state: RootState) => state.app.createMode);
	const content: JSX.Element = activeTab === 'classes' ? <CreateClassModalContent /> : <CreatePropertyModalContent />;

	return (
		<Modal dialogClassName='create-modal-layout' centered show={createMode}>
			<Modal.Header>
				<Modal.Title>{t('createNew')}</Modal.Title>
			</Modal.Header>
			{content}
		</Modal>
	);
};

export default CreateModal;
