// Router
import { Navigate, useLocation } from 'react-router-dom';
// Hooks
import useKeycloak from '../hooks/useKeycloak';

export const PublicRoute = ({ children }) => {
	const { isInitialized, catalogRoles } = useKeycloak();
	const location = useLocation();

	if (isInitialized) {
		if (false) {
			return location.pathname === '/unauthorized' ? <Navigate to='/' /> : children;
		}
	}

	return children;
};
