import { configureStore } from '@reduxjs/toolkit';
import classesReducer from './classesReducer';
import propertiesReducer from './propertiesReducer';
import appReducer from './appReducer';
import sortingReducer from './sortingReducer';
import tableFilterReducer from './tableFilterReducer';
import mappingsReducer from './mappingsReducer';
import TablePropertiesReducer from './tablePropertiesReducer';

export const store = configureStore({
	reducer: {
		classes: classesReducer,
		properties: propertiesReducer,
		TableProperties: TablePropertiesReducer,
		mappings: mappingsReducer,
		sorting: sortingReducer,
		tableFilter: tableFilterReducer,
		app: appReducer,
		
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
