// MUI
import { Step, Stepper, StepLabel } from '@mui/material';
// REACT
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Status } from '../../../services/catalog/models/Status';
// STYLES
import { ColorlibStepIcon } from './StatusStepperStyles';

interface Props {
	active: boolean;
	activeStatus: Status;
	setStatus: Dispatch<SetStateAction<Status>>;
}

const StatusStepper = (props: Props) => {
	const [activeStep, setActiveStep] = useState(Object.keys(Status).indexOf(props.activeStatus));
	const [hasChanged, setHasChanged] = useState(false);

	/**
	 * Css class selector based on status
	 * @param status Each Status gets a color. Yellow is Default
	 * @returns void
	 */
	const getDotColorByStatus = (status: string): string => {
		if (status === 'Draft') {
			return 'blue-dot';
		}
		if (status === 'Published') {
			return 'green-dot';
		}
		return 'yellow-dot';
	};

	useEffect(() => {
		setActiveStep(Object.keys(Status).indexOf(props.activeStatus));
		if (!props.active) {
			setHasChanged(false);
		}
	}, [props.activeStatus, props.active]);

	/**
	 * Only one step can be done per edit.
	 * @param index current step.
	 * @param label label of current step.
	 * @returns HTMLElement to be rendered.
	 */
	const getStepContent = (index: number, label: string) => {
		if (index - 1 === activeStep && props.active && !hasChanged) {
			return (
				<button
					onClick={() => {
						setActiveStep(index);
						props.setStatus(Status[label]);
						setHasChanged(true);
					}}
				>
					{label} <span className={getDotColorByStatus(label) + " small-spacing-left"}></span>
				</button>
			);
		}
		if (index + 1 === activeStep && props.active && hasChanged)
		{
			return (
				<button
					onClick={() => {
						setActiveStep(index);
						props.setStatus(Status[label]);
						setHasChanged(false);
					}}
				>
					{label} <span className={getDotColorByStatus(label) + " small-spacing-left"}></span>
				</button>
			);
		}
		return (
			<>
				{label}
				<span className={getDotColorByStatus(label) + " small-spacing-left"}></span>
			</>
		);
	};

	return (
		<Stepper alternativeLabel activeStep={activeStep}>
			{(Object.keys(Status) as (keyof typeof Status)[]).map((label, index) => {
				const stepProps = {};
				const labelProps = {};
				return (
					<Step key={label} {...stepProps}>
						<StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
							{getStepContent(index, label)}
						</StepLabel>
					</Step>
				);
			})}
		</Stepper>
	);
};
export default StatusStepper;
