// REACT
import React, { useState } from 'react';
// STYLING
import '../../../Details.scss';
// COMPONENTS
import { Paper } from '@mui/material';
import Mappings from './Mappings';
import MappingConfigurator from './MappingConfiguration/MappingConfigurator';
import { RootState } from '../../../../../context/store';
import { useAppSelector } from '../../../../../context/hooks';

const ClassDetailsPropertiesModalContent = () => {
	const addMappingMode = useAppSelector((state: RootState) => state.mappings.addMappingMode);

	return (
		<div className='details-class-properties-layout'>{addMappingMode ? <MappingConfigurator /> : <Mappings />}</div>
	);
};

export default ClassDetailsPropertiesModalContent;
