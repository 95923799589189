import { Row } from 'react-bootstrap';
import { PropertyAssignmentDto, ObjectPropertyDto, PropertyDto, Status } from '../../../../../services/catalog';
import { useCallback, useEffect, useState } from 'react';
import { EventType, Severity } from '../../../../../models/models';
import { useAppDispatch } from '../../../../../context/hooks';
import { setToast } from '../../../../../context/appReducer';
import '../../PropertyModal.scss';
import PropertyAssignments from '../../../PropertyAssignment/PropertyAssignments';
import PropertyAssignmentList from '../../../PropertyAssignment/PropertyAssignmentList';

interface Props {
	property: ObjectPropertyDto;
	event: EventType | null;
	onUpdateProperty: (property: PropertyDto) => void;
	setEvent: React.Dispatch<React.SetStateAction<EventType | null>>;
}

const ObjectPropertyDetails = ({ property, event, onUpdateProperty, setEvent }: Props) => {
	const dispatch = useAppDispatch();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [assignedProperties, setAssignedProperties] = useState<PropertyAssignmentDto[]>(
		property.properties ? property.properties : [],
	);
	const updateProperty = useCallback(() => {
		try {
			const updatedProperty = { ...property, properties: assignedProperties };
			setEditMode(false);
			onUpdateProperty(updatedProperty);
		} catch (error: any) {
			dispatch(setToast({ show: true, message: error.message, severity: Severity.alert }));
			setEvent(EventType.UPDATE_PROPERTY_EVENT);
			console.log(error.message);
			return;
		}
	}, [property, assignedProperties, dispatch, onUpdateProperty, setEvent]);

	useEffect(() => {
		if (event === EventType.UPDATE_PROPERTY_EVENT) {
			if (property.status === Status.Draft)
			{
			setEditMode(true);
			return;
			}
		} else if (event === EventType.SAVE_PROPERTY_EVENT) {
			updateProperty();
		} else if (event === EventType.RESET_PROPERTY_EVENT) {
			// resetInputs();
		}
		setEditMode(false);
	}, [event]);

	useEffect(() => {
		setAssignedProperties(property.properties ? property.properties : []);
	}, [property]);

	return (
		<>
			<Row>
				{editMode ? 
				<PropertyAssignments
					property={property}
					title="Object properties"
					event={event}
					propertyType={property.propertyDiscriminator!}
					assignedProperties={assignedProperties}
					setAssignedProperties={setAssignedProperties}
					onCreateProperty={updateProperty}
					resetEvent={() => setEvent(null)}
				/>
				:
				<PropertyAssignmentList
					title="Object properties"
					columnProperties={assignedProperties}
					property={property}
				/>
			
			}
				</Row>
		</>
	);
};
export default ObjectPropertyDetails;
