export interface ToastBar {
	show: boolean;
	message: string;
	severity: Severity;
}

export interface SortingOption {
	property: string;
	ascending: boolean;
}

export enum Severity {
	default = 'default',
	warning = 'warning',
	alert = 'alert',
	success = 'success',
}

export enum EventType {
	CREATE_PROPERTY_EVENT = "CREATE_PROPERTY_EVENT",
	RESET_PROPERTY_EVENT = "RESET_PROPERTY_EVENT",
	UPDATE_PROPERTY_EVENT = "UPDATE_PROPERTY_EVENT",
	SAVE_PROPERTY_EVENT = "SAVE_PROPERTY_EVENT"
}
