// REACT
import { useEffect, useState } from 'react';
// STYLING
import '../../../../Details.scss';
// COMPONENTS
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Stepper } from '@mui/material';
import { Text } from '@lbc-toolkit/text';
import { Button } from '@lbc-toolkit/button';
import { TextInput } from '@lbc-toolkit/textinput';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Form, InputGroup } from 'react-bootstrap';
// API
import { PropertyDto, PropertySetDto, PropertySetsService } from '../../../../../../services/catalog';
//REDUX
import { useAppDispatch, useAppSelector } from '../../../../../../context/hooks';
import { setToast } from '../../../../../../context/appReducer';
import { Severity } from '../../../../../../models/models';
import { RootState } from '../../../../../../context/store';
import { setPropertySets } from '../../../../../../context/propertiesReducer';
import { setSelectedPropertySet } from '../../../../../../context/mappingsReducer';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

interface Props {
	setBlockNextStep: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectablePropertySets = ({ setBlockNextStep }: Props) => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const propertySets: PropertySetDto[] = useAppSelector((state: RootState) => state.properties.propertySets);
	const selectedProperty: PropertyDto | null = useAppSelector((state: RootState) => state.mappings.selectedProperty);
	const selectedPropertySet: string | null = useAppSelector((state: RootState) => state.mappings.selectedPropertySet);
	const [newPropertySetName, setNewPropertySetName] = useState<string>('');
	const [searchInput, setSearchInput] = useState<string>('');

	/**
	 * Called after initial render to get and set the propertySets
	 */
	useEffect(() => {
		PropertySetsService.getPropertySets()
			.then((retrievedPropertySets: PropertySetDto[]) => {
				dispatch(setPropertySets(retrievedPropertySets));
				setBlockNextStep(false);
			})
			.catch((_) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('errorFetchingData'),
					}),
				);
			});
	}, []);

	useEffect(() => {
		if (selectedPropertySet) {
			setBlockNextStep(false);
		} else {
			setBlockNextStep(true);
		}
	}, [selectedPropertySet])

	/**
	 * Handles the creation of new propertySets
	 * @returns
	 */
	const OnCreate = () => {
		if (newPropertySetName === '') return;

		const propertySet: PropertySetDto = {
			id: '',
			name: newPropertySetName,
			properties: [],
		};

		PropertySetsService.createPropertySets(propertySet).then((createdResult: PropertySetDto) => {
			propertySet.id = (createdResult as PropertySetDto).id;
			dispatch(setPropertySets([...propertySets, propertySet]));
			dispatch(
				setToast({
					show: true,
					severity: Severity.success,
					message: t('createPropertySetSuccess'),
				}),
			);
			setNewPropertySetName('');
		});
	};

	/**
	 * Decides if a propertyset can be shown or not, depending the input of the searchbard
	 * @param component
	 * @returns
	 */
	const displayPropertySet = (propertySet: PropertySetDto) => {
		const lowerSearchInput: string = searchInput.toLocaleLowerCase();
		return propertySet.name?.toLowerCase().includes(lowerSearchInput);
	};

	return (
		<>
			<div className='selectable-content fullheight'>
				<Text type='h6' className='mb-2'>
					{t('selectPropertySet')}
				</Text>
				{selectedProperty!.propertySetId !== null ? (
					<div className='py-2'>
						{t('info_propertysetAlreadyAssignedForProperty')}
						<p className='text-bold'>{selectedProperty?.propertySetName}</p>
					</div>
				) : (
					<>
						<div className='justify-content-space-between-horizontal mb-2'>
							<TextInput
								disabled={selectedPropertySet === null}
								placeholder={t('search')}
								onChange={(value: string) => {
									setSearchInput(value);
								}}
							/>
						</div>

						<div className='selectable-tableBody'>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>{t('name')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{propertySets
										.filter((propertySet) => displayPropertySet(propertySet))
										.map((propertySet: PropertySetDto) => {
											return (
												<TableRow className='selectable-row'>
													<TableCell
														className={
															propertySet.id === selectedProperty?.propertySetId
																? 'selectable-item-active'
																: 'selectable-item'
														}
														onClick={() =>
															dispatch(setSelectedPropertySet(propertySet!.id!))
														}
													>
														{propertySet.name}
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</div>
					</>
				)}
			</div>

			<div>
				{selectedProperty!.propertySetId !== null ? (
					<></>
				) : (
					<InputGroup className='mb-3'>
						<Form.Control
							className='searchbar'
							placeholder={t('placeholder_createNewPropertySet')}
							value={newPropertySetName}
							onChange={(event) => {
								setNewPropertySetName(event.target.value);
							}}
						/>
						<Button icon={faPlus} className='searchbar-button' onClick={OnCreate} />
					</InputGroup>
				)}
			</div>
		</>
	);
};

export default SelectablePropertySets;
