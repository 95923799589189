// MUI
import { Stepper, Step, StepLabel, createTheme, withStyles, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { PropertyDto, PropertySetDto } from '../../../services/catalog';
import { RootState } from '../../../context/store';
import catalogTheme from '../../../styling/themes/catalogTheme';

interface PropertyUpdateStatusStepperProps {
	currentStep: number;
	maxSteps: number;
	onSave: () => void;
	onCancel: () => void;
	updateStep: (number) => void;
	blockNextStep: boolean;
}

const PropertyUpdateStatusStepperWithButtons = ({
	currentStep,
	maxSteps,
	onSave,
	onCancel,
	updateStep,
	blockNextStep,
}: PropertyUpdateStatusStepperProps) => {
	const [activeStep, setActiveStep] = useState(currentStep);
	const selectedPropertySet: string | null = useAppSelector((state: RootState) => state.mappings.selectedPropertySet);
	const selectedProperty: PropertyDto | null = useAppSelector((state: RootState) => state.mappings.selectedProperty);
	const propertyUpdateStatusDict = { 0: 'Properties', 1: 'Property Set', 2: 'Configuration' };

	const onNext = () => {
		if (currentStep === maxSteps) {
			onSave();
		} else {
			updateStep(currentStep + 1);
		}
	};

	const onBack = () => {
		if (currentStep === 0) {
			onCancel();
		} else {
			updateStep(currentStep - 1);
		}
	};

	const returnStep = (index: number, label: string) => {
		return (
			<button
				onClick={() => {
					setActiveStep(index);
					updateStep(index);
				}}
			>
				{label}
			</button>
		);
	};

	const getStepContent = (index: number, label: string) => {
		if (index != activeStep && selectedProperty != null && selectedPropertySet != null) {
			return returnStep(index, label);
		} else if (
			index == maxSteps &&
			index - 1 == activeStep &&
			selectedProperty != null &&
			selectedPropertySet != null
		) {
			return returnStep(activeStep + 1, label);
		} else if (
			index == maxSteps - 1 &&
			index - 1 == activeStep &&
			selectedProperty != null &&
			selectedPropertySet == null
		) {
			return returnStep(activeStep + 1, label);
		} else if (index == 0 && index != activeStep && selectedProperty != null && selectedPropertySet == null) {
			return returnStep(0, label);
		} else {
			return <>{label}</>;
		}
	};

	return (
		<ThemeProvider theme={catalogTheme}>
			<Button onClick={onBack}>{currentStep === 0 ? 'Cancel' : 'Back'}</Button>
			<Stepper alternativeLabel activeStep={activeStep} sx={{ width: 800 }}>
				{Object.entries(propertyUpdateStatusDict).map(([key, value]) => (
					<Step key={key}>
						<StepLabel>{value}</StepLabel>
					</Step>
				))}
			</Stepper>
			<Button disabled={blockNextStep} onClick={onNext}>
				{currentStep === maxSteps ? 'Save' : 'Next'}
			</Button>
		</ThemeProvider>
	);
};
export default PropertyUpdateStatusStepperWithButtons;
