import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Severity, ToastBar } from '../models/models';

export interface AppReducerState {
	user: null;
	token: string;
	language: string;
	toast: ToastBar;
	activeTab: string;
	activeDetailsTab: string;
	createMode: boolean;
	detailsMode: boolean;
	deleteMode: boolean;
}

export const initialState: AppReducerState = {
	user: null,
	token: '',
	language: 'EN',
	toast: { show: false, message: '', severity: Severity.default },
	activeTab: 'classes',
	activeDetailsTab: 'general',
	createMode: false,
	detailsMode: false,
	deleteMode: false,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<null>) => {
			state.user = action.payload;
		},
		setLanguage: (state, action: PayloadAction<string>) => {
			state.language = action.payload;
		},
		setToast: (state, action: PayloadAction<ToastBar>) => {
			state.toast = action.payload;
		},
		setActiveTab: (state, action: PayloadAction<string>) => {
			state.activeTab = action.payload;
		},
		setActiveDetailsTab: (state, action: PayloadAction<string>) => {
			state.activeDetailsTab = action.payload;
		},
		setCreateMode: (state, action: PayloadAction<boolean>) => {
			state.createMode = action.payload;
		},
		setDetailsMode: (state, action: PayloadAction<boolean>) => {
			state.detailsMode = action.payload;
		},
		setDeleteMode: (state, action: PayloadAction<boolean>) => {
			state.deleteMode = action.payload;
		},
	},
});

export const {
	setUser,
	setLanguage,
	setToast,
	setActiveTab,
	setActiveDetailsTab,
	setCreateMode,
	setDetailsMode,
	setDeleteMode,
} = appSlice.actions;

export default appSlice.reducer;
