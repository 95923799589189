// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-content-body {
  width: 100%;
  margin-top: 20px;
  height: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.table-container {
  padding: 0 1vw;
}

.table-row {
  word-wrap: break-all;
  border: none !important;
}

.header-icon {
  padding-left: 0.5vw;
}

.green-dot {
  height: 8px;
  width: 8px;
  background-color: #71db5c;
  border-radius: 50%;
  display: inline-block;
}

.blue-dot {
  height: 8px;
  width: 8px;
  background-color: #5190d8;
  border-radius: 50%;
  display: inline-block;
}

.yellow-dot {
  height: 8px;
  width: 8px;
  background-color: #f0e440;
  border-radius: 50%;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/Properties/PropertyTable.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EAEA,aAAA;EACA,sBAAA;AAAD;;AAGA;EACC,cAAA;AAAD;;AAGA;EACC,oBAAA;EACA,uBAAA;AAAD;;AAGA;EACC,mBAAA;AAAD;;AAGA;EACC,WAAA;EACA,UAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;AAAD;;AAGA;EACC,WAAA;EACA,UAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;AAAD;;AAGA;EACC,WAAA;EACA,UAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;AAAD","sourcesContent":[".dashboard-content-body {\r\n\twidth: 100%;\r\n\tmargin-top: 20px;\r\n\theight: 10px;\r\n\tflex-grow: 1;\r\n\t// overflow-x: auto;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n}\r\n\r\n.table-container {\r\n\tpadding: 0 1vw;\r\n}\r\n\r\n.table-row {\r\n\tword-wrap: break-all;\r\n\tborder: none !important;\r\n}\r\n\r\n.header-icon {\r\n\tpadding-left: 0.5vw;\r\n}\r\n\r\n.green-dot {\r\n\theight: 8px;\r\n\twidth: 8px;\r\n\tbackground-color: #71db5c;\r\n\tborder-radius: 50%;\r\n\tdisplay: inline-block;\r\n}\r\n\r\n.blue-dot {\r\n\theight: 8px;\r\n\twidth: 8px;\r\n\tbackground-color: #5190d8;\r\n\tborder-radius: 50%;\r\n\tdisplay: inline-block;\r\n}\r\n\r\n.yellow-dot {\r\n\theight: 8px;\r\n\twidth: 8px;\r\n\tbackground-color: #f0e440;\r\n\tborder-radius: 50%;\r\n\tdisplay: inline-block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
