import React from 'react';
// STYLING
import './DetailsNavbar.scss';
// COMPONENTS
import { Button } from 'react-bootstrap';
// REDUX
import { RootState } from '../../context/store';
import { useAppDispatch, useAppSelector } from '../../context/hooks';
import { setActiveDetailsTab } from '../../context/appReducer';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

function DetailsNavbar() {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const activeTab: string = useAppSelector((state: RootState) => state.app.activeTab);
	const activeDetailsTab: string = useAppSelector((state: RootState) => state.app.activeDetailsTab);
	const currentProperty = useAppSelector((state: RootState) => state.properties.currentProperty);

	return (
		<div className='details-navbar'>
			<Button
				className={
					activeDetailsTab === 'general' ? 'details-navbar-activeTab-button' : 'details-navbar-buttons'
				}
				onClick={() => dispatch(setActiveDetailsTab('general'))}
			>
				{t('general')}
			</Button>
			<div>
				<Button
					className={
						activeDetailsTab === 'properties' ? 'details-navbar-activeTab-button' : 'details-navbar-buttons'
					}
					onClick={() => dispatch(setActiveDetailsTab('properties'))}
				>
					{t('properties')}
				</Button>
			</div>

			<Button
				className={
					activeDetailsTab === 'localization' ? 'details-navbar-activeTab-button' : 'details-navbar-buttons'
				}
				onClick={() => dispatch(setActiveDetailsTab('localization'))}
			>
				{t('localization')}
			</Button>
		</div>
	);
}

export default DetailsNavbar;
