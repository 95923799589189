import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//API
import { ClassificationDto } from '../services/catalog';

export interface ClassesReducerState {
	classifications: ClassificationDto[];
	editMode: boolean;
	currentClass: ClassificationDto | null;
	selectedParentClass: ClassificationDto | null;
	treeviewSearch: string;
	classTreeNeedsReload: boolean;
}

export const initialState: ClassesReducerState = {
	classifications: [],
	editMode: false,
	currentClass: null,
	selectedParentClass: null,
	treeviewSearch: '',
	classTreeNeedsReload: true,
};

export const classesSlice = createSlice({
	name: 'classes',
	initialState,
	reducers: {
		setClassifications: (state, action: PayloadAction<ClassificationDto[]>) => {
			state.classifications = action.payload;
		},
		setEditMode: (state, action: PayloadAction<boolean>) => {
			state.editMode = action.payload;
		},
		setCurrentClass: (state, action: PayloadAction<ClassificationDto | null>) => {
			state.currentClass = action.payload;
		},
		setSelectedParentClass: (state, action: PayloadAction<ClassificationDto | null>) => {
			state.selectedParentClass = action.payload;
		},
		setTreeviewSearch: (state, action: PayloadAction<string>) => {
			state.treeviewSearch = action.payload;
		},
		setClassTreeNeedsReload: (state, action: PayloadAction<boolean>) => {
			state.classTreeNeedsReload = action.payload;
		},
	},
});

export const {
	setClassifications,
	setEditMode,
	setCurrentClass,
	setSelectedParentClass,
	setTreeviewSearch,
	setClassTreeNeedsReload,
} = classesSlice.actions;

export default classesSlice.reducer;
