/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClassificationDto } from '../models/ClassificationDto';
import type { CreateClassificationDto } from '../models/CreateClassificationDto';
import type { UpdateClassificationDto } from '../models/UpdateClassificationDto';
import type { UsageType } from '../models/UsageType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClassificationsService {

    /**
     * Creates new class
     * @param requestBody Create class body
     * @returns ClassificationDto Success
     * @throws ApiError
     */
    public static createClassification(
requestBody?: CreateClassificationDto,
): CancelablePromise<ClassificationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/classifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates an existing class
     * @param requestBody Update class input
     * @returns ClassificationDto Success
     * @throws ApiError
     */
    public static updateClassification(
requestBody?: UpdateClassificationDto,
): CancelablePromise<ClassificationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/classifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets all classes filtered by given keyword
     * @param searchValue Keyword to search classes
     * @param usageType Usage type of the classifications
     * @returns ClassificationDto Success
     * @throws ApiError
     */
    public static searchClassifications(
searchValue?: string,
usageType?: UsageType,
): CancelablePromise<Array<ClassificationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/classifications',
            query: {
                'searchValue': searchValue,
                'usageType': usageType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes an existing class
     * @param id Delete class input
     * @returns void 
     * @throws ApiError
     */
    public static deleteClassification(
id: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/classifications/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets class by given id.
     * @param id Id of the class
     * @returns ClassificationDto Success
     * @throws ApiError
     */
    public static getClassification(
id: string,
): CancelablePromise<ClassificationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/classifications/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets class tree for the given maximum depth
     * @returns ClassificationDto Success
     * @throws ApiError
     */
    public static getRootClassifications(): CancelablePromise<Array<ClassificationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/classifications/roots',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets children of classes
     * @param parentId Parent id to query children of entity
     * @returns ClassificationDto Success
     * @throws ApiError
     */
    public static getChildren(
parentId: string,
): CancelablePromise<Array<ClassificationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/classifications/{parentId}/children',
            path: {
                'parentId': parentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
