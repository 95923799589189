// REACT
import React, { useEffect, useState, useCallback } from 'react';

// COMPONENTS
import { DataTable } from '../../overview/DataTable';
import { MRT_ColumnDef } from 'material-react-table';

// STYLING
import '../Form.scss';
import './PropertyTable.scss';

// REDUX
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import { setToast } from '../../../context/appReducer';
import { setProperties, setPropertiesNeedReload } from '../../../context/propertiesReducer';

// MODELS & SERVICES
import { PropertyDto, PropertiesService, PropertyDiscriminator, SimplePropertyDto } from '../../../services/catalog';
import { Severity, SortingOption } from '../../../models/models';

// TRANSLATION
import { useTranslate } from '@tolgee/react';
import PropertiesActionButtons from '../../overview/PropertiesActionButtons';
import PropertyIcon from '../../icons/PropertyIcon';

const PropertyTable = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const properties = useAppSelector((state: RootState) => state.properties.properties);
	const propertiesNeedReload = useAppSelector((state: RootState) => state.properties.propertiesNeedReload);
	const [loading, setLoading] = useState<boolean>(false);

	const columns: MRT_ColumnDef<PropertyDto>[] = [
		{
			accessorKey: 'name',
			header: t('name'),
			enableHiding: true,
		},
		{
			accessorFn: (row) => {
				if (row.propertyDiscriminator === PropertyDiscriminator.Simple) {
					return (row as SimplePropertyDto).dataType;
				} else {
					return row.propertyDiscriminator;
				}
			},
			id: 'dataType', // Add an id for the accessor function
			header: t('dataType'),
			enableHiding: true,
			Cell: ({ cell }) => {
				return (
					<div className='flex items-center gap-2'>
						<PropertyIcon property={cell.row.original} showPropertyName={false} />
					</div>
				);
			},
		},
		{
			accessorKey: 'unittype',
			header: t('unitType'),
			enableHiding: true,
		},
		{
			accessorKey: 'unit',
			header: t('unit'),
			enableHiding: true,
		},
		{
			accessorKey: 'status',
			header: 'Status',
			enableHiding: true,
			Cell: ({ cell }) => {
				const status = cell.getValue<string>(); // Get the status value
				const dotColor = getDotColorByStatus(status); // Get the dot color based on the status
				return (
					<div className='flex items-center gap-2'>
						<span className={`dot ${dotColor}`}></span>
						{status}
					</div>
				);
			},
		},
	];

	const getDotColorByStatus = (status: string): string => {
		if (status === 'Draft') {
			return 'blue-dot';
		}
		if (status === 'Published') {
			return 'green-dot';
		}
		return 'yellow-dot';
	};

	const fetchProperties = useCallback(() => {
		setLoading(true);
		PropertiesService.getProperties()
			.then((propertiesRetrieved: PropertyDto[]) => {
				dispatch(setProperties(propertiesRetrieved));
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(
					setToast({
						show: true,
						message: 'Error fetching data.',
						severity: Severity.warning,
					}),
				);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [dispatch]);

	useEffect(() => {
		fetchProperties();
	}, [fetchProperties]);

	useEffect(() => {
		if (propertiesNeedReload) {
			fetchProperties();
			dispatch(setPropertiesNeedReload(false));
		}
	}, [dispatch, propertiesNeedReload, fetchProperties]);

	return (
		<div className='w-100 flex h-[70vh] flex-col gap-2'>
			<div className='h-0 flex-grow'>
				<DataTable
					columns={columns}
					data={properties ?? []}
					state={{ isLoading: loading }}
					actionButtons={({ row }) => (
						<>
							<PropertiesActionButtons property={row.original} />
						</>
					)}
				/>
			</div>
		</div>
	);
};

export default PropertyTable;
