// MUI
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCompassDrafting, faScrewdriverWrench, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StepIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColorlibStepIconRoot = styled('div')<{
	ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#000',
	width: 30,
	height: 30,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        backgroundColor: 'rgb(255, 208, 0)',
	}),
	...(ownerState.completed && {
        backgroundColor: 'rgb(255, 208, 0)',
	}),
}));

/**
 * Render a Icon in Stepper for each Step
 * @param props 
 * @returns 
 */
export function ColorlibStepIcon(props: StepIconProps) {
	const { active, completed, className } = props;

	const icons: { [index: string]: React.ReactElement } = {
		1: <FontAwesomeIcon icon={faCompassDrafting as IconProp} />,
		2: <FontAwesomeIcon icon={faUpload as IconProp} />,
		3: <FontAwesomeIcon icon={faScrewdriverWrench as IconProp} />,
	};

	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}