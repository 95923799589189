/**
 * Dynamically sorts an array by given key that has to be a property of the Datatype of the array.
 * @param sortingOption Key of property.
 * @param sortAscending Indicates if sorting ascending or descending
 * @returns is greater than as number.
 */
export default function dynamicSort<T>(sortingOption: keyof T, sortAscending: boolean = true) {
	return function (a: T, b: T) {
		if (sortAscending) return (a[sortingOption] ?? '') < (b[sortingOption] ?? '') ? -1 : 1;
		else return (a[sortingOption] ?? '') > (b[sortingOption] ?? '') ? -1 : 1;
	};
}
