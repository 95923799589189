import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropertyDto, PropertySetDto } from '../services/catalog';

export interface PropertiesReducerState {
	properties: PropertyDto[];
	propertySets: PropertySetDto[];
	editMode: boolean;
	currentProperty: PropertyDto | null;
	addedProperty: PropertyDto | null;
	addEnumMode: boolean;
	propertiesNeedReload: boolean;
}

export const initialState: PropertiesReducerState = {
	properties: [],
	propertySets: [],
	editMode: false,
	currentProperty: null,
	addedProperty: null,
	addEnumMode: false,
	propertiesNeedReload: false,
};

export const propertiesSlice = createSlice({
	name: 'properties',
	initialState,
	reducers: {
		setProperties: (state, action: PayloadAction<PropertyDto[]>) => {
			state.properties = action.payload;
		},
		setPropertySets: (state, action: PayloadAction<PropertySetDto[]>) => {
			state.propertySets = action.payload;
		},
		addToProperties: (state, action: PayloadAction<PropertyDto>) => {
			state.properties = [...state.properties, action.payload];
		},
		removeFromProperties: (state, action: PayloadAction<PropertyDto>) => {
			state.properties = state.properties.filter((property) => property.id !== action.payload.id);
		},
		setCurrentProperty: (state, action: PayloadAction<PropertyDto | null>) => {
			state.currentProperty = action.payload;
		},
		setEditMode: (state, action: PayloadAction<boolean>) => {
			state.editMode = action.payload;
		},
		updateProperty: (state, action: PayloadAction<PropertyDto>) => {
			state.currentProperty = action.payload;
			state.editMode = false;
		},
		setAddEnumMode: (state, action: PayloadAction<boolean>) => {
			state.addEnumMode = action.payload;
		},
		setPropertiesNeedReload: (state, action: PayloadAction<boolean>) => {
			state.propertiesNeedReload = action.payload;
		},
	},
});

export const {
	setCurrentProperty,
	addToProperties,
	removeFromProperties,
	setEditMode,
	updateProperty,
	setProperties,
	setPropertySets,
	setAddEnumMode,
	setPropertiesNeedReload,
} = propertiesSlice.actions;

export default propertiesSlice.reducer;
