import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MappingDto, PropertyDto, PropertySetDto } from '../services/catalog';

export interface MappingReducerState {
	addMappingMode: boolean;
	selectables: PropertyDto[];
	mappings: MappingDto[];
	selectedProperty: PropertyDto | null;
	selectedPropertySet: string | null;
	selectedMapping: MappingDto | null;
	mappingsListNeedsReload: boolean;
}

export const initialState: MappingReducerState = {
	addMappingMode: false,
	selectables: [],
	mappings: [],
	selectedProperty: null,
	selectedPropertySet: null,
	selectedMapping: null,
	mappingsListNeedsReload: false,
};

export const mappingSlice = createSlice({
	name: 'mappings',
	initialState,
	reducers: {
		setAddMappingMode: (state, action: PayloadAction<boolean>) => {
			state.addMappingMode = action.payload;
		},
		setSelectables: (state, action: PayloadAction<PropertyDto[]>) => {
			state.selectables = action.payload;
		},
		setMappings: (state, action: PayloadAction<MappingDto[]>) => {
			state.mappings = action.payload;
		},
		setSelectedMapping: (state, action: PayloadAction<MappingDto | null>) => {
			state.selectedMapping = action.payload;
		},
		removeSelectable: (state, action: PayloadAction<PropertyDto>) => {
			state.selectables.forEach((element: PropertyDto, index: number) => {
				if (element.id === action.payload.id) {
					state.selectables.splice(index, 1);
				}
			});
		},
		addToMappings: (state, action: PayloadAction<MappingDto>) => {
			state.mappings = [...state.mappings, action.payload];
		},
		removeFromMapping: (state, action: PayloadAction<MappingDto>) => {
			state.mappings.forEach((element: MappingDto, index: number) => {
				if (element.property?.id === action.payload.property?.id) {
					state.mappings.splice(index, 1);
				}
			});
		},
		addToSelectables: (state, action: PayloadAction<PropertyDto>) => {
			state.selectables = [...state.selectables, action.payload];
		},
		setSelectedProperty: (state, action: PayloadAction<PropertyDto | null>) => {
			state.selectedProperty = action.payload;
		},
		setSelectedPropertySet: (state, action: PayloadAction<string | null>) => {
			state.selectedPropertySet = action.payload;
		},
		setMappingsListNeedsReload: (state, action: PayloadAction<boolean>) => {
			state.mappingsListNeedsReload = action.payload;
		},
	},
});

export const {
	setAddMappingMode,
	setSelectables,
	setMappings,
	setSelectedProperty,
	setSelectedPropertySet,
	addToMappings,
	removeSelectable,
	removeFromMapping,
	addToSelectables,
	setSelectedMapping,
	setMappingsListNeedsReload,
} = mappingSlice.actions;

export default mappingSlice.reducer;
