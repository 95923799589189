import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassificationDto } from '../services/catalog';

export interface SortingReducerState {
	classTreeSorting: keyof ClassificationDto;
	sortAscending: boolean;
}

export const initialState: SortingReducerState = {
	classTreeSorting: 'name',
	sortAscending: true,
};

export const sortingSlice = createSlice({
	name: 'sorting',
	initialState,
	reducers: {
		setClassTreeSorting: (state, action: PayloadAction<keyof ClassificationDto>) => {
			state.classTreeSorting = action.payload;
		},
		setSortAscending: (state, action: PayloadAction<boolean>) => {
			state.sortAscending = action.payload;
		}
	},
});

export const { setClassTreeSorting, setSortAscending } = sortingSlice.actions;

export default sortingSlice.reducer;
