// LIBRARIES
import { useEffect, useState } from 'react';

// EXTERNAL COMPONENTS
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from '@lbc-toolkit/dropdown';
import { Spinner } from '@lbc-toolkit/spinner';

// ICONS & TYPES
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRotateRight, faWarning } from '@fortawesome/free-solid-svg-icons';
import { EventType } from '../../../../models/models';
import {
	DataType,
	PropertyDiscriminator,
	PropertyDto,
	PropertiesService,
	SimplePropertyDto,
	UnitDto,
	UnitTypeDto,
} from '../../../../services/catalog';

// STYLE
import '../PropertyModal.scss';
import { useTranslate } from '@tolgee/react';

interface Props {
	event: EventType | null;
	dataType: DataType;
	onCreateProperty: (property: PropertyDto, discriminator: PropertyDiscriminator) => void;
	resetEvent: () => void;
	onValidationChange: (isValid: boolean) => void;
}

/**
 * Component to create unit property form.
 *
 * @param {EventType | null} event - Event to handle property actions.
 * @param {(property: PropertyDto) => void} onCreateProperty - Callback for creating a property.
 * @param {() => void} resetEvent - Callback to reset the event.
 * @param {(isValid: boolean) => void} onValidationChange - Callback to notify validation status.
 */
const CreateUnitPropertyForm = ({ event, dataType, onCreateProperty, resetEvent, onValidationChange }: Props) => {
	const { t } = useTranslate();
	const [unittypes, setUnittypes] = useState<UnitTypeDto[]>([]);
	const [isUnitTypeLoadingFailed, setUnitTypeLoadingFailed] = useState<boolean>(false);
	const [isLoadingUnitTypes, setLoadingUnitTypes] = useState<boolean>(false);
	const [unitType, setUnitType] = useState<UnitTypeDto | null>(null);
	const [unit, setUnit] = useState<UnitDto | null>(null);

	useEffect(() => {
		onValidationChange(Boolean(unit) && Boolean(unitType));
	}, [unit, unitType, onValidationChange]);

	useEffect(() => {
		const buildUnitProperty = () => {
			try {
				const property: SimplePropertyDto = {};
				property.unit = unit?.name;
				property.unittype = unitType?.name;
				property.dataType = dataType;
				onCreateProperty(property, PropertyDiscriminator.Simple);
			} catch (error: any) {
				console.error(error.message);
				resetEvent();
			}
		};
		if (event === EventType.CREATE_PROPERTY_EVENT) {
			buildUnitProperty();
		}
	}, [event, onCreateProperty, resetEvent]);

	useEffect(() => {
		const loadUnitTypes = async () => {
			setLoadingUnitTypes(true);
			const startTime = Date.now();
			try {
				const retrievedUnittypes: UnitTypeDto[] = await PropertiesService.getUnitTypes();
				setUnittypes(retrievedUnittypes);
				setUnitTypeLoadingFailed(false);
			} catch (error: any) {
				setUnitTypeLoadingFailed(true);
			} finally {
				const elapsedTime = startTime - Date.now();
				const timeout = 300;
				setTimeout(() => {
					setLoadingUnitTypes(false);
				}, timeout - elapsedTime);
			}
		};
		loadUnitTypes();
	}, []);

	return (
		<>
			<Row>
				<Col md={2} className='bold-header'>
					{t('unitType')}
				</Col>
				<Col md={4}>
					{isUnitTypeLoadingFailed && !isLoadingUnitTypes && (
						<div className='unittype-error-message'>
							<FontAwesomeIcon className='pe-1 error' size='sm' icon={faWarning as IconProp} />
							<div className='error'>{t('loadingFailed')}</div>
							<FontAwesomeIcon className='ps-1 reload' icon={faRotateRight as IconProp} />
						</div>
					)}
					{isLoadingUnitTypes && (
						<div>
							<Spinner spin className='pe-1' />
							{t('load')}
						</div>
					)}
					<Dropdown
						width='100%'
						label={t('required')}
						disabled={isUnitTypeLoadingFailed || isLoadingUnitTypes}
						placeholder={t('placeholder_inputUnitType')}
						onSelect={(value: UnitTypeDto) => setUnitType(value)}
						selected={unitType}
						options={unittypes.map((ut: UnitTypeDto) => ({ label: ut.name, value: ut }))}
						isClearable
					/>
				</Col>
				<Col md={2} className='bold-header'>
					{t('unit')}
				</Col>
				<Col md={4}>
					<Dropdown
						width='100%'
						label={t('required')}
						placeholder={t('placeholder_inputUnit')}
						onSelect={(value: UnitDto) => setUnit(value)}
						selected={unit}
						options={unitType?.units?.map((u: UnitDto) => ({ label: u.name, value: u }))}
						disabled={!Boolean(unitType)}
					/>
				</Col>
			</Row>
		</>
	);
};

export default CreateUnitPropertyForm;
