// React Libraries
import React, { useCallback, useEffect, useState } from 'react';

// External Libraries & Components
import { Col, Row } from 'react-bootstrap';
import { EventType, Severity } from '../../../../../models/models';
import { Dropdown } from '@lbc-toolkit/dropdown';

// Models & Constants
import { PropertyDto, SimplePropertyDto, Status, UnitDto, UnitTypeDto } from '../../../../../services/catalog';
import { useDispatch } from 'react-redux';
import { setToast } from '../../../../../context/appReducer';
// TRANSLATION
import { useTranslate } from '@tolgee/react';
import useUnitsNet from '../../../../../hooks/useUnitsNet';

interface Props {
	property: SimplePropertyDto;
	event: EventType | null;
	setEvent: React.Dispatch<React.SetStateAction<EventType | null>>;
	onUpdateProperty: (property: PropertyDto) => void;
	onValidationChange: (isValid: boolean) => void;
}

const UnitPropertyDetails = ({ property, event, onUpdateProperty, setEvent, onValidationChange }: Props) => {
	const { t } = useTranslate();
	const dispatch = useDispatch();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [unittype, setUnitType] = useState<string>(property.unittype ? property.unittype : '');
	const [unit, setUnit] = useState<string>(property.unit ? property.unit : '');

	const updateProperty = useCallback(() => {
		try {
			if (!unittype || !unit) {
				throw new Error(t('info_inputfieldsAreEmpty'));
			}
			const updatedProperty = { ...property, unittype, unit };
			setEditMode(false);
			onUpdateProperty(updatedProperty);
		} catch (error: any) {
			dispatch(setToast({ show: true, message: error.message, severity: Severity.alert }));
			setEvent(EventType.UPDATE_PROPERTY_EVENT);
			console.log(error.message);
			return;
		}
	}, [unittype, unit, property, t, dispatch, onUpdateProperty, setEvent]);

	useEffect(() => {
		if (event === EventType.UPDATE_PROPERTY_EVENT) {
			if (property.status === Status.Draft) {
				setEditMode(true);
				return;
			}
		} else if (event === EventType.SAVE_PROPERTY_EVENT) {
			updateProperty();
		} else if (event === EventType.RESET_PROPERTY_EVENT) {
			resetInputs();
		}
		setEditMode(false);
	}, [event, setEditMode, updateProperty, setEvent]);

	useEffect(() => {
		onValidationChange(Boolean(unit) && Boolean(unittype));
	}, [unit, unittype, onValidationChange]);

	const resetInputs = () => {
		setUnitType(property.unittype ? property.unittype : '');
		setUnit(property.unit ? property.unit : '');
	};

	return editMode ? (
		<EditContent property={property} onUnitChange={setUnit} />
	) : (
		<DetailsContent unitType={unittype} unit={unit} />
	);
};

export default UnitPropertyDetails;

interface DetailsContentProps {
	unitType: string;
	unit: string;
}

const DetailsContent = ({ unitType, unit }: DetailsContentProps) => {
	const { t } = useTranslate();

	return (
		<Row>
			<Col md={2} style={{ fontWeight: 'bold' }}>
				{t('unitType')}
			</Col>
			<Col md={4}>{unitType}</Col>

			<Col md={2} style={{ fontWeight: 'bold' }}>
				{t('unit')}
			</Col>
			<Col md={4}>{unit}</Col>
		</Row>
	);
};

interface EditContentProps {
	property: SimplePropertyDto;
	onUnitChange: (value: string) => void;
}

const EditContent = ({ property, onUnitChange }: EditContentProps) => {
	const { t } = useTranslate();
	const [unitType, setUnitType] = useState<UnitTypeDto | null>(null);
	const [unit, setUnit] = useState<UnitDto | null>(null);

	const { isPending, unitTypes, unitTypeByName, unitByName } = useUnitsNet();

	useEffect(() => {
		if (property.unittype) {
			setUnitType(unitTypeByName(property.unittype) ?? null);
		}
	}, [property, unitTypes]);

	useEffect(() => {
		if (unitType && property.unit) {
			setUnit(unitByName(unitType.name || '', property.unit || '') ?? null);
		}
	}, [unitType, property]);

	return (
		<Row>
			<Col md={2} style={{ fontWeight: 'bold' }}>
				{t('unitType')}
			</Col>
			<Col md={4}>{unitType?.name ?? ''}</Col>

			<Col md={2} style={{ fontWeight: 'bold' }}>
				{t('unit')}
			</Col>

			<Col md={4}>
				<Dropdown
					width='100%'
					placeholder={t('placeholder_inputUnit')}
					onSelect={(value: UnitDto) => {
						setUnit(value);
						onUnitChange(value.name || '');
					}}
					selected={unitByName(unitType?.name || '', unit?.name || '')}
					options={
						unitType == null ? null : unitType?.units?.map((u: UnitDto) => ({ label: u.name, value: u }))
					}
					disabled={!Boolean(unitType)}
				/>
			</Col>
		</Row>
	);
};
