import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSort, faSortAsc, faSortDesc } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { setClassTreeSorting, setSortAscending } from '../../../context/sortingReducer';
import { RootState } from '../../../context/store';
import { ClassificationDto } from '../../../services/catalog';
import { useTranslate } from '@tolgee/react';

const TreeSortingMenu = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const sortingOption = useAppSelector((state: RootState) => state.sorting.classTreeSorting);
	const sortAscending = useAppSelector((state: RootState) => state.sorting.sortAscending);

	const renderIndicatorIcon = (property: keyof ClassificationDto) => {
		if (sortingOption === property) {
			if (sortAscending) {
				return <FontAwesomeIcon icon={faSortAsc as IconProp} className='pe-1' />;
			}
			return <FontAwesomeIcon icon={faSortDesc as IconProp} className='pe-1' />;
		}
		return <FontAwesomeIcon icon={faSort as IconProp} className='pe-1' />;
	};

	const onClickSortOption = (property: keyof ClassificationDto) => {
		if (sortingOption === property) {
			dispatch(setSortAscending(!sortAscending));
		} else {
			dispatch(setClassTreeSorting(property));
			dispatch(setSortAscending(true));
		}
	};

	return (
		<>
			<MenuItem onClick={() => onClickSortOption('name')}>{renderIndicatorIcon('name')} Name</MenuItem>
			<MenuItem onClick={() => onClickSortOption('usageType')}>
				{renderIndicatorIcon('usageType')} {t('usageType')}
			</MenuItem>
		</>
	);
};

export default TreeSortingMenu;
