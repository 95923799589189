// REACT
import React, { useState } from 'react';
// STYLING
import './Mappings.scss';
// COMPONENTS
import { MappingDto, PropertyDto, PropertySetDto } from '../../../../../services/catalog';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import MappingTableProperty from './MappingTableProperty';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

interface Props {
	pset: PropertySetDto;
	mset: MappingDto[];
	deletable?: boolean;
}

const MappingTablePropertySet = ({ pset, mset, deletable = true }: Props) => {
	const { t } = useTranslate();
	const [expanded, setExpanded] = useState<boolean>(true);

	return (
		<>
			<tr>
				<td colSpan={3} className='mapping-propertyset'>
					{pset.name}
					<IconButton onClick={() => setExpanded(!expanded)}>
						{expanded ? (
							<FontAwesomeIcon
								className='fa-xs mapping-propertyset-expand-icon'
								icon={faAngleDown as IconProp}
							/>
						) : (
							<FontAwesomeIcon
								className='fa-xs mapping-propertyset-expand-icon'
								icon={faAngleRight as IconProp}
							/>
						)}
					</IconButton>
				</td>
			</tr>
			{expanded &&
				mset
					.filter((mapping: MappingDto) => {
						return mapping.property && pset.properties?.includes(mapping.property);
					})
					.map((mapping: MappingDto) => {
						return (
							<MappingTableProperty deletable={deletable} key={mapping.property!.id} mapping={mapping} />
						);
					})}
		</>
	);
};

export default MappingTablePropertySet;
