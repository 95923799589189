//Components
import { Button } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
// Services
import KeycloakService from '../../../services/KeyCloakService';
// Styling
import './Unauthorized.scss';
//Translation
import { useTranslate } from '@tolgee/react';

const UnauthorizedPage = () => {
	const { t } = useTranslate();

	const handleLogout = () => {
		KeycloakService.doLogout();
	};

	return (
		<div className='unauthorized-container'>
			<Text type='h1'>{t('accessDenied')}</Text>
			<Text type='p'>{t('missingRights')}</Text>
			<Button className='logout-button' onClick={handleLogout} label={t('logout')} />
		</div>
	);
};
export default UnauthorizedPage;
