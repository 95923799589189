// COMPONENTS
import ClassesContainer from './ClassesContainer';
import CreateModal from '../modals/CreateModal';
import DeleteModal from '../modals/DeleteModal';
import DetailsModal from '../modals/DetailsModal';
import Footer from './footer/Footer';
import PropertiesContainer from './PropertiesContainer';
import SideNavBar from '../navigation/sideNavbar/SideNavBar';
import TopNavBar from '../navigation/topNavBar/TopNavBar';
import ColumnOptionsModal from '../modals/ColumnOptionsModal';

// LIBRARIES & EXTERNAL UTILITIES
import { showToast } from '@lbc-toolkit/toast';

// STYLING
import './Container.scss';

// REDUX
import { useAppSelector, useAppDispatch } from '../../context/hooks';
import { RootState } from '../../context/store';
import { setToast } from '../../context/appReducer';

// MODELS
import { Severity, ToastBar } from '../../models/models';

const Dashboard = () => {
	const dispatch = useAppDispatch();
	const toast: ToastBar = useAppSelector((state: RootState) => state.app.toast);
	const activeTab = useAppSelector((state: RootState) => state.app.activeTab);
	const container: JSX.Element = activeTab === 'properties' ? <PropertiesContainer /> : <ClassesContainer />;

	/**
	 * Toast gets activated as a central component.
	 * @param message Message of the toast
	 * @param severity Type of the toast for coloring
	 */
	function activateToast(message: string, severity: Severity) {
		showToast({
			message: message,
			type: severity,
			autoHide: true,
			delay: 3000,
		});
		dispatch(setToast({ show: false, message: 'clicked', severity: Severity.default }));
	}

	if (toast.show) {
		activateToast(toast.message, toast.severity);
	}

	return (
		<div className='dashboard'>
			<TopNavBar />
			<div>
				<div className='body-container'>
					<CreateModal />
					<DetailsModal />
					<DeleteModal />
					<ColumnOptionsModal />
					<SideNavBar />
					<div className='content-container'>
						<div className='content-layout'>{container}</div>
						<Footer />
					</div>
				</div>
			</div>
		</div>
	);
};
export default Dashboard;
