// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-route-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/routes/Routes.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AACD","sourcesContent":[".spinner-route-loading {\r\n\tposition: absolute;\r\n\ttop: 50%; \r\n\tleft: 50%; \r\n\twidth: 50px;\r\n\theight: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
