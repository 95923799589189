// COMPONENTS
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faListTree } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as LiebherrBtn } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
// STYLING
import '../Form.scss';
// API
import { setCreateMode } from '../../../context/appReducer';
import { useAppDispatch } from '../../../context/hooks';
import { setCurrentClass } from '../../../context/classesReducer';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

const ClassHeader = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();

	/**
	 * Opens a modal to create a classification
	 */
	const OnCreateNew = () => {
		//selected class is reset, so its usagetype doesn't affect the child element to be created
		dispatch(setCurrentClass(null));
		dispatch(setCreateMode(true));
	};

	return (
		<div className='dashboard-content-header'>
			<div className='header-controls'>
				<Text className='header-text' type='h2'>
					{t('classifications')}
				</Text>
				<FontAwesomeIcon className='header-icon' size='xs' icon={faListTree as IconProp} />
				<div className='vr' />
				<LiebherrBtn icon={faPlus} label={t('createNew')} onClick={OnCreateNew} />
			</div>
		</div>
	);
};

export default ClassHeader;
