import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TableFilterState {
	columnsOptionsVisible: boolean;
	nameVisibility: boolean;
	datatypeVisibility: boolean;
	unittypeVisibility: boolean;
	unitVisibility: boolean;
	statusVisibility: boolean;
	sortingOption: string;
}

export const initialState: TableFilterState = {
	columnsOptionsVisible: false,
	nameVisibility: true,
	datatypeVisibility: true,
	unittypeVisibility: true,
	unitVisibility: true,
	statusVisibility: true,
	sortingOption: 'ID',
};

export const tableFiltersSlice = createSlice({
	name: 'connectors',
	initialState,
	reducers: {
		setColumnsOptionsVisible: (state, action: PayloadAction<boolean>) => {
			state.columnsOptionsVisible = action.payload;
		},
		setNameVisibility: (state, action: PayloadAction<boolean>) => {
			state.nameVisibility = action.payload;
		},
		setDatatypeVisibility: (state, action: PayloadAction<boolean>) => {
			state.datatypeVisibility = action.payload;
		},
		setUnittypeVisibility: (state, action: PayloadAction<boolean>) => {
			state.unittypeVisibility = action.payload;
		},
		setUnitVisibility: (state, action: PayloadAction<boolean>) => {
			state.unitVisibility = action.payload;
		},
		setStatusVisibility: (state, action: PayloadAction<boolean>) => {
			state.statusVisibility = action.payload;
		},
		setSortingOption: (state, action: PayloadAction<string>) => {
			state.sortingOption = action.payload;
		},
	},
});

export const {
	setColumnsOptionsVisible,
	setNameVisibility,
	setDatatypeVisibility,
	setUnittypeVisibility,
	setUnitVisibility,
	setSortingOption,
	setStatusVisibility,
} = tableFiltersSlice.actions;

export default tableFiltersSlice.reducer;
