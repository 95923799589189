/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PropertySetDto } from '../models/PropertySetDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PropertySetsService {

    /**
     * Endpoint to create a new propertySet.
     * @param requestBody Data the propertySet will be created with.
     * @returns PropertySetDto Success
     * @throws ApiError
     */
    public static createPropertySets(
requestBody?: PropertySetDto,
): CancelablePromise<PropertySetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/propertysets',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to get every propertySet.
     * @returns PropertySetDto Success
     * @throws ApiError
     */
    public static getPropertySets(): CancelablePromise<Array<PropertySetDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/propertysets',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to get a property with a given id.
     * @param id Id to search for a property.
     * @returns PropertySetDto Success
     * @throws ApiError
     */
    public static getPropertySet(
id: string,
): CancelablePromise<PropertySetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/propertysets/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
