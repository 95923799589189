// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapping-table {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mapping-propertyset {
  width: 100%;
  padding-top: 0.5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray !important;
}

.mapping-property {
  padding-left: 3vw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(243, 243, 243) !important;
}

.mapping-property:hover {
  background-color: rgb(243, 243, 243);
}

.mapping-property-type {
  font-style: italic;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/Classes/Details/Mappings/Mappings.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,kBAAA;EACA,kBAAA;AACD;;AAEA;EACC,WAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,6CAAA;AACD;;AAEA;EACC,iBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,sDAAA;AACD;;AAEA;EACC,oCAAA;AACD;;AACA;EACC,kBAAA;EACA,iBAAA;AAED","sourcesContent":[".mapping-table {\r\n\twidth: 100%;\r\n\toverflow-x: hidden;\r\n\toverflow-y: scroll;\r\n}\r\n\r\n.mapping-propertyset {\r\n\twidth: 100%;\r\n\tpadding-top: 0.5vh;\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: space-between;\r\n\tborder-bottom: 1px solid lightgray !important;\r\n}\r\n\r\n.mapping-property {\r\n\tpadding-left: 3vw;\r\n\theight: 100%;\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\tborder-bottom: 1px solid rgb(243, 243, 243) !important;\r\n}\r\n\r\n.mapping-property:hover {\r\n\tbackground-color: rgb(243, 243, 243);\r\n}\r\n.mapping-property-type {\r\n\tfont-style: italic;\r\n\ttext-align: right;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
