/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PropertyDiscriminator {
    Undefined = 'Undefined',
    Simple = 'Simple',
    Table = 'Table',
    Object = 'Object',
}
