import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import { ClassificationDto } from '../../../services/catalog';
import ClassificationTreeNode from './ClassificationTreeNode';
import dynamicSort from '../../../utils/DynamicSort';

type ClassificationTreeItemsProps = {
	classifications: ClassificationDto[];
	isOverview: boolean;
	creation?: boolean;
};

const ClassificationTreeItems = ({ classifications, isOverview, creation }: ClassificationTreeItemsProps) => {
	const classTreeSorting = useAppSelector((state: RootState) => state.sorting.classTreeSorting);
	const classTreeAscending = useAppSelector((state: RootState) => state.sorting.sortAscending);
	const [sortedClassification, setSortedClassification] = useState<ClassificationDto[]>(classifications);

	/**
	 * UseEffect hook to update cranetype list based on sorting options
	 */
	useEffect(() => {
		const tempClassifications = [...classifications];
		tempClassifications.sort(dynamicSort(classTreeSorting, classTreeAscending));
		setSortedClassification(tempClassifications);
	}, [classTreeSorting, classTreeAscending, classifications]);

	if (classifications.length === 0) return <></>;

	return (
		<ul className='treeview-ul'>
			{sortedClassification.map((children) => (
				<ClassificationTreeNode classification={children} key={children.id} isOverview={isOverview} />
			))}
		</ul>
	);
};

export default ClassificationTreeItems;
