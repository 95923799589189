// React Libraries
import { useEffect } from 'react';

// Models & Constants
import { PropertyDto, SimplePropertyDto, Status } from '../../../../../services/catalog';
import { EventType } from '../../../../../models/models';

// STYLE
import '../../PropertyModal.scss';

interface Props {
	property: SimplePropertyDto;
	event: EventType | null;
	onUpdateProperty: (property: PropertyDto) => void;
}

const BasicPropertyDetails = ({ property, event, onUpdateProperty }: Props) => {

	useEffect(() => {
		if (event === EventType.UPDATE_PROPERTY_EVENT) {
			if (property.status === Status.Draft)
			{
            	return;
			}
		} else if (event === EventType.SAVE_PROPERTY_EVENT) {
			updateProperty();
		}
	}, [event]);

	const updateProperty = () => {
		try {
			const updatedProperty = { ...property };
			onUpdateProperty(updatedProperty);
		} catch (error: any) {
			console.log(error.message);
		}
	};

	return (
		<>
		</>
	);
};

export default BasicPropertyDetails;

