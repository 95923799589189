/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UsageType {
    Component = 'Component',
    Connector = 'Connector',
    Collector = 'Collector',
    Composition = 'Composition',
    CompositionVariant = 'CompositionVariant',
}
