import { useState } from 'react';
// COMPONENTS
import { faSearch, faSort } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@lbc-toolkit/spinner';
import { Button, Form, InputGroup } from 'react-bootstrap';
import ClassificationTree from '../../navigation/treeviews/ClassificationTree';
// HOOKS
import useSearchedClassifications from '../../../hooks/UseSearchedClassifications';
// STYLE
import '../Form.scss';
// REDUX
import { setTreeviewSearch } from '../../../context/classesReducer';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
// API
// TRANSLATIONS
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Menu, Tooltip } from '@mui/material';
import TreeSortingMenu from '../../navigation/treeviews/TreeSortingMenu';
// TRANSLATION
import { useTranslate } from '@tolgee/react';
// CONFIGURATIONS

const ClassTreeview = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const treeviewSearch = useAppSelector((state: RootState) => state.classes.treeviewSearch);
	const { classifications } = useSearchedClassifications(treeviewSearch);
	const classTreeNeedsReload = useAppSelector((state: RootState) => state.classes.classTreeNeedsReload);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onSearchChange = (event) => {
		const value = event?.target?.value ?? '';
		dispatch(setTreeviewSearch(value.trim()));
	};

	return (
		<>
			<InputGroup className='mt-3'>
				<Button className='me-1 searchbar-button' onClick={handleClick}>
					<FontAwesomeIcon className='fa-xs' id='sortIconBtn' color='black' icon={faSort as IconProp} />
				</Button>
				<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
					<TreeSortingMenu />
				</Menu>
				<Button className='searchbar-button'>
					{classTreeNeedsReload ? (
						<Spinner style={{ color: 'black' }} spin={true} />
					) : (
						<FontAwesomeIcon
							className='fa-xs'
							id='searchIconBtn'
							color='black'
							icon={faSearch as IconProp}
						/>
					)}
				</Button>
				<Tooltip
					title={treeviewSearch.trim().length < 3 ? t('atLeast3Characters') : ''}
					placement='bottom-start'
				>
					<Form.Control
						className='searchbar'
						placeholder={t('search')}
						value={treeviewSearch}
						onChange={onSearchChange}
					/>
				</Tooltip>
			</InputGroup>
			<div className='dashboard-content-body'>
				<div className='treeview-container' id='treeview'>
					{classTreeNeedsReload && (
						<div className='table-spinner'>
							<Spinner spin={true} />
						</div>
					)}
					<ClassificationTree classifications={classifications} isOverview />
				</div>
			</div>
		</>
	);
};

export default ClassTreeview;
