import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TablePropertyDto } from '../services/catalog';

export interface TablePropertiesReducerState {
	currentTableProperty: null | TablePropertyDto;
	TableProperties: TablePropertyDto[];
	TablePropertiesNeedsReload: boolean;
	editMode: boolean;
}

export const initialState: TablePropertiesReducerState = {
	currentTableProperty: null,
	TableProperties: [],
	TablePropertiesNeedsReload: false,
	editMode: false,
};

export const TablePropertiesSlice = createSlice({
	name: 'TableProperties',
	initialState,
	reducers: {
		setTablePropertiesNeedReload: (state, action: PayloadAction<boolean>) => {
			state.TablePropertiesNeedsReload = action.payload;
		},
		setTableProperties: (state, action: PayloadAction<TablePropertyDto[]>) => {
			state.TableProperties = action.payload;
		},
		addToTableProperties: (state, action: PayloadAction<TablePropertyDto>) => {
			state.TableProperties.push(action.payload);
		},
		setCurrentTableProperty: (state, action: PayloadAction<TablePropertyDto | null>) => {
			state.currentTableProperty = action.payload;
		},
		setEditMode: (state, action: PayloadAction<boolean>) => {
			state.editMode = action.payload;
		},
		removeFromTableProperties: (state, action: PayloadAction<TablePropertyDto>) => {
			state.TableProperties = state.TableProperties.filter(
				(property) => property.id !== action.payload.id
			);
		}
	},
});

export const {
	setTablePropertiesNeedReload,
	setTableProperties,
	addToTableProperties,
	setCurrentTableProperty,
	setEditMode,
	removeFromTableProperties,
} = TablePropertiesSlice.actions;

export default TablePropertiesSlice.reducer;
