//REACT
import React, { useState } from 'react';
// COMPONENTS
import liebherrLogo from '../../../assets/images/Liebherr-Logo.png';
import { faCircleInfo, faGlobe, faGrid } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, Button, IconButton, Menu, MenuItem, Popover } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
// STYLING
import './TopNavBar.scss';
// REDUX
import NavigationGrid from './NavigationGrid';
import { useTolgee } from '@tolgee/react';
import { languages } from '../../../translations/languages';

const TopNavBar = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [languageSelectOpen, setLanguageSelectOpen] = useState<boolean>(false);
	const tolgee = useTolgee(['language']);

	const languageCodeToWord = (code) => {
		switch (code) {
			case 'en':
				return 'English';
			case 'de':
				return 'Deutsch';
			default:
				return code;
		}
	};

	/**
	 * Sets the language depending on the selected language in the language dropdown
	 * @param event
	 */
	const OnLanguageSelectOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (!event) {
			return;
		}
		setAnchorEl(event.currentTarget);
		setLanguageSelectOpen(true);
	};

	/**
	 * Handles the closing of the language dropdown
	 */
	const OnLanguageSelectClose = () => {
		setAnchorEl(null);
		setLanguageSelectOpen(false);
	};

	return (
		<div className='navbar-container'>
			<img src={liebherrLogo} className='navbar-logo' />
			<div className='navbar-actions'>
				<div className='navbar-action'>
					<div>{languageCodeToWord(tolgee.getLanguage())}</div>
				</div>
				<div className='navbar-action'>
					<div className='navbar-icon'>
						<IconButton onClick={OnLanguageSelectOpen}>
							<FontAwesomeIcon size='lg' icon={faGlobe as IconProp} />
						</IconButton>
						<Popover
							className='navbar-languageSelect-popover'
							open={languageSelectOpen}
							onClose={OnLanguageSelectClose}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							{languages.map((language: string, key: number) => {
								return (
									<div
										key={key}
										className={
											tolgee.getLanguage() === language
												? 'navbar-languageSelect-languageSelected'
												: 'navbar-selectedLanguage-language'
										}
										onClick={() => tolgee.changeLanguage(language)}
									>
										{languageCodeToWord(language)}
									</div>
								);
							})}
						</Popover>
					</div>
				</div>
				<div className='navbar-action'>
					<IconButton onClick={() => {}}>
						<FontAwesomeIcon size='lg' icon={faCircleInfo as IconProp} />
					</IconButton>
				</div>
				<div>
					<NavigationGrid />
				</div>
				<div className='navbar-action'>
					<Avatar className='navbar-avatar'>MM</Avatar>
				</div>
			</div>
		</div>
	);
};
export default TopNavBar;
