// REACT
import { useEffect, useState } from 'react';

// MODELS & SERVICES
import {
	PropertyAssignmentDto,
	PropertyDiscriminator,
	PropertyDto,
} from '../../../../services/catalog';
import { EventType } from '../../../../models/models';

// STYLE
import '../PropertyModal.scss';
import PropertyAssignments from '../../PropertyAssignment/PropertyAssignments';

interface Props {
	event: EventType | null;
	onCreateProperty: (property: PropertyDto, discriminator: PropertyDiscriminator) => void;
	resetEvent: () => void;
	onValidationChange: (isValid: boolean) => void;
}

const CreateObjectPropertyForm = ({ event, onCreateProperty, resetEvent, onValidationChange }: Props) => {
	const [assignedProperties, setAssignedProperties] = useState<PropertyAssignmentDto[]>([]);

	useEffect(() => {
		onValidationChange(true);
	}, [onValidationChange]);

	return (
		<PropertyAssignments
			property={undefined}
			title='Object properties'
			event={event}
			propertyType={PropertyDiscriminator.Object}
			assignedProperties={assignedProperties}
			setAssignedProperties={setAssignedProperties}
			onCreateProperty={onCreateProperty}
			resetEvent={resetEvent}
		/>
	);
};
export default CreateObjectPropertyForm;