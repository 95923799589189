// LIBRARIES
import { useEffect } from 'react';

// EXTERNAL COMPONENTS
import {  Row } from 'react-bootstrap';

// ICONS & TYPES
import { EventType } from '../../../../models/models';
import { DataType, PropertyDiscriminator, PropertyDto, SimplePropertyDto } from '../../../../services/catalog';

// STYLE
import '../PropertyModal.scss'

interface Props {
    event: EventType | null;
    dataType: DataType;
    onCreateProperty: (property: PropertyDto, discriminator: PropertyDiscriminator) => void;
    resetEvent: () => void;
	onValidationChange: (isValid: boolean) => void;
}

/**
 * Component to create basic property form.
 * 
 * @param {EventType | null} event - Event to handle property actions.
 * @param {(property: PropertyDto) => void} onCreateProperty - Callback for creating a property.
 * @param {() => void} resetEvent - Callback to reset the event.
 */
const CreateBasicPropertyForm = ({ event, dataType, onCreateProperty, resetEvent, onValidationChange }: Props) => {

    useEffect(() => {
        /**
         * Function to build a basic property and call the creation callback.
         */
        const buildBasicProperty = () => {
            try {
                const property: SimplePropertyDto = {
                    dataType: dataType
                };
                onCreateProperty(property, PropertyDiscriminator.Simple);
            } catch (error) {
                console.error("Es gab einen Fehler beim Erstellen der Basic Property:", error);
                resetEvent();
            }
        }

        /**
         * Function to reset the properties of the form.
         */
        const resetBasicProperty = () => {
            resetEvent();
        }

        if(event === EventType.RESET_PROPERTY_EVENT){
            resetBasicProperty()
            return;
        }
        if(event === EventType.CREATE_PROPERTY_EVENT){
            buildBasicProperty();
        }
    }, [event, onCreateProperty, resetEvent]);

	useEffect(() => {
		onValidationChange(true);
	}, [onValidationChange]);



	return (
		<Row />
	);
};

export default CreateBasicPropertyForm