// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  height: 8vh;
  background-color: black;
  color: white;
  padding: 0vh 2vw 0vh 3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  width: 30vw;
  display: flex;
  justify-content: space-evenly;
}`, "",{"version":3,"sources":["webpack://./src/components/container/footer/Footer.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,uBAAA;EACA,YAAA;EACA,wBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACD;;AAEA;EACC,WAAA;EACA,aAAA;EACA,6BAAA;AACD","sourcesContent":[".footer {\r\n\theight: 8vh;\r\n\tbackground-color: black;\r\n\tcolor: white;\r\n\tpadding: 0vh 2vw 0vh 3vw;\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\talign-items: center;\r\n}\r\n\r\n.footer-links {\r\n\twidth: 30vw;\r\n\tdisplay: flex;\r\n\tjustify-content: space-evenly;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
