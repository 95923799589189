import { Col, Row } from 'react-bootstrap';
import { EventType } from '../../../../../models/models';
import { DataType, PropertyDto, SimplePropertyDto } from '../../../../../services/catalog';
import BasicPropertyDetails from './BasicPropertyDetails';
import UnitPropertyDetails from './UnitPropertyDetails';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

type Props = {
	property: SimplePropertyDto;
	event: EventType | null;
	onUpdateProperty: (property: PropertyDto) => void;
	setEvent: React.Dispatch<React.SetStateAction<EventType | null>>;
	onValidationChange: (isValid: boolean) => void;
};

const SimplePropertyDetails = ({ property, event, onUpdateProperty, setEvent, onValidationChange }: Props) => {
	const { t } = useTranslate();

	const getExtendenFormByDataType = () => {
		switch ((property as SimplePropertyDto).dataType) {
			case DataType.Integer:
			case DataType.Double: {
				return (
					<UnitPropertyDetails
						property={property}
						event={event}
						onUpdateProperty={onUpdateProperty}
						onValidationChange={onValidationChange}
						setEvent={setEvent}
					/>
				);
			}
			case DataType.Boolean:
			case DataType.String: {
				return <BasicPropertyDetails property={property} event={event} onUpdateProperty={onUpdateProperty} />;
			}
		}
		return <></>;
	};

	return (
		<>
			<Row className='pb-4 pt-4'>
				<Col md={2} className='text-bold'>
					{t('dataType')}
				</Col>
				<Col md={10}>{property.dataType}</Col>
			</Row>
			<hr />
			{getExtendenFormByDataType()}
		</>
	);
};

export default SimplePropertyDetails;
