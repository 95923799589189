/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DataType {
    String = 'String',
    Double = 'Double',
    Integer = 'Integer',
    Boolean = 'Boolean',
}
