// REACT
import React, { useState } from 'react';
// COMPONENTS
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRectangleList, faListTree } from '@fortawesome/pro-regular-svg-icons';
// STYLING
import './SideNavBar.scss';

// REDUX
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import { setActiveTab } from '../../../context/appReducer';
// TRANSLATION
import { useTranslate } from '@tolgee/react';

const SideNavBar = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const activeTab = useAppSelector((state: RootState) => state.app.activeTab);
	const [expanded, setExpanded] = useState<boolean>(false);

	/**
	 * Handles the navbar expansion
	 */
	function OnNavbarExpand() {
		if (expanded) {
			setExpanded(false);
			document.getElementById('sidenavbar')!.style.width = '4vw';
		} else {
			setExpanded(true);
			document.getElementById('sidenavbar')!.style.width = '12vw';
		}
	}

	return (
		<div className='side-navbar-container' id='sidenavbar'>
			<div>
				<button
					className={activeTab === 'classes' ? 'side-navbar-buttons-active' : 'side-navbar-buttons'}
					onClick={() => {
						dispatch(setActiveTab('classes'));
					}}
				>
					{expanded ? <h6>{t('classifications')}</h6> : <FontAwesomeIcon icon={faListTree as IconProp} />}
				</button>
				<button
					className={
						activeTab === 'properties'
							? 'side-navbar-buttons-active box-shadow'
							: 'side-navbar-buttons box-shadow'
					}
					onClick={() => {
						dispatch(setActiveTab('properties'));
					}}
				>
					{expanded ? <h6>{t('properties')}</h6> : <FontAwesomeIcon icon={faRectangleList as IconProp} />}
				</button>
			</div>
			<div>
				<button
					className='side-navbar-collapse-button'
					onClick={() => {
						OnNavbarExpand();
					}}
				>
					{expanded ? (
						<FontAwesomeIcon icon={faAngleLeft as IconProp} size='lg' />
					) : (
						<FontAwesomeIcon icon={faAngleRight as IconProp} size='lg' />
					)}
				</button>
			</div>
		</div>
	);
};
export default SideNavBar;
