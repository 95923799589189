import { ClassificationDto } from '../../../services/catalog';
import ClassificationTreeItems from './ClassificationTreeItems';

type ClassificationTreeProps = {
	classifications: ClassificationDto[];
	isOverview?: boolean;
};

const ClassificationTree = ({ classifications, isOverview = false }: ClassificationTreeProps) => {
	return (
		<div>
			<ClassificationTreeItems classifications={classifications} isOverview={isOverview} />
		</div>
	);
};

export default ClassificationTree;
