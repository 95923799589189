//React
import React from 'react';
//Components
import { Paper } from '@mui/material';
import ClassHeader from '../forms/Classes/ClassHeader';
import ClassTreeview from '../forms/Classes/ClassTreeview';
//Style
import './Container.scss';

const ClassesContainer = () => {
	return (
		<Paper className='content-container-layout'>
			<ClassHeader />
			<ClassTreeview />
		</Paper>
	);
};

export default ClassesContainer;
