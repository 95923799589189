import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropertyDiscriminator, PropertyDto, SimplePropertyDto } from '../../services/catalog';
import {
	faBracketsCurly,
	faCalculatorSimple,
	faFont,
	faHashtag,
	faTable,
	faToggleOn,
} from '@fortawesome/pro-solid-svg-icons';

interface Props {
	property: PropertyDto;
	showPropertyName: boolean;
}

const PropertyIcon = ({ property, showPropertyName }: Props) => {
	const getIcon = () => {
		if (property.propertyDiscriminator === PropertyDiscriminator.Simple) {
			const simplePropertyIcons = {
				'String': faFont,
				'Integer': faHashtag,
				'Double': faHashtag,
				'Boolean': faToggleOn,
			};
			return <FontAwesomeIcon icon={simplePropertyIcons[(property as SimplePropertyDto).dataType!]} />;
		}

		const propertyDiscriminatorIcons = {
			[PropertyDiscriminator.Object]: faBracketsCurly,
			[PropertyDiscriminator.Table]: faTable,
		};

		return (
			<FontAwesomeIcon icon={propertyDiscriminatorIcons[property.propertyDiscriminator!] || faCalculatorSimple} />
		);
	};

	const getDataType = (): string => {
		if (property.propertyDiscriminator === PropertyDiscriminator.Simple) {
			return (property as SimplePropertyDto).dataType!;
		}
		return property.propertyDiscriminator!;
	};

	return (
		<>
			{getIcon()}
			<span className='ml-2'>{showPropertyName ? property.name : getDataType()}</span>
		</>
	);
};

export default PropertyIcon;
