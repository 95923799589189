import { IconButton } from '@mui/material';
import { setDeleteMode, setDetailsMode } from '../../context/appReducer';
import { useAppDispatch } from '../../context/hooks';
import { setCurrentProperty } from '../../context/propertiesReducer';
import { PropertyDto, Status } from '../../services/catalog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useEffect, useState } from 'react';

interface Props {
	property: PropertyDto;
}

const PropertiesActionButtons = ({ property }: Props) => {
	const [canDelete, setCanDelete] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const isDraftStatus = property.status === Status.Draft;
		setCanDelete(isDraftStatus);
	}, [property]);

	/**
	 * Called when remove button gets pressed to edit a specific property, found by given id.
	 * @param id Id of the property to delete.
	 */
	function OnEditPropertyBtnAction(property: PropertyDto) {
		dispatch(setCurrentProperty(property));
		dispatch(setDetailsMode(true));
	}

	/**
	 * Called when remove button gets pressed to delete a specific property, found by given id.
	 * @param id Id of the property to delete.
	 */
	function OnRemovePropertyBtnAction(property: PropertyDto) {
		dispatch(setCurrentProperty(property));
		dispatch(setDeleteMode(true));
	}

	return (
		<>
			<IconButton property='span' size='small' onClick={() => OnEditPropertyBtnAction(property)}>
				<FontAwesomeIcon color='#3489eb' className='fa-xs' icon={faPen as IconProp} />
			</IconButton>
			{canDelete && (
				<IconButton
					className='table-cell-actions-buttons'
					property='span'
					size='small'
					onClick={() => OnRemovePropertyBtnAction(property)}
				>
					<FontAwesomeIcon color='#eb4034' className='fa-xs' icon={faTrash as IconProp} />
				</IconButton>
			)}
		</>
	);
};
export default PropertiesActionButtons;
